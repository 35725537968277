html{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  width: 100%;
}

.button-primary{
  background: #91152b;
  border: none;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: .02857em;
  border-radius: 5px;
  width: auto;
  min-width: 150px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: .1s ease-in-out;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  margin-left: 2px;
}

.btn {
  background: #428bca;
  border: #357ebd solid 1px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  padding: 8px 15px;
  text-decoration: none;
  text-align: center;
  min-width: 60px;
  position: relative;
  transition: color .1s ease;
}
.btn:hover {
  background: #357ebd;
}
.btn.btn-big {
  font-size: 18px;
  padding: 15px 20px;
  min-width: 100px;
}
.btn-close {
  color: #aaa;
  font-size: 30px;
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 0;
}
.btn-close:hover {
  color: #919191;
}
.modal:before {
  content: "";
  display: none;
  background: rgba(0,0,0,0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.modal:target:before {
  display: block;
}
.modal:target .modal-dialog {
  -webkit-transform: translate(0,0);
  -ms-transform: translate(0,0);
  transform: translate(0,0);
  top: 20%;
}

.modal-dialog-foto{
  overflow-x: scroll;
}

.modal:target .modal-dialog-foto {
  -webkit-transform: translate(0,0);
  -ms-transform: translate(0,0);
  transform: translate(0,0);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  margin-left: 0;
}

.modal-dialog {
  background: #fefefe;
  border: #333 solid 1px;
  border-radius: 5px;
  margin-left: -200px;
  position: fixed;
  left: 50%;
  top: -100%;
  z-index: 11;
  width: 360px;
  -webkit-transform: translate(0,-500%);
  -ms-transform: translate(0,-500%);
  transform: translate(0,-500%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal-body {
  padding: 20px;
  text-align: center;
}
.modal-header, .modal-footer {
  padding: 10px 20px;
}
.modal-header {
  border-bottom: #eee solid 1px;
}
.modal-header h2 {
  font-size: 20px;
}
.modal-footer {
  border-top: #eee solid 1px;
  text-align: center;
  position:fixed;
  width: 100%;
}


body{
  background: #494949;
  /* background-image: url('./assets/images/login_bg.jpg'); */
}

.selected{
  background: green;
}

#video {
  border: 1px solid #999;
  width: 98%;
  max-width: 860px;
}

.foto-moldura{
  border: 1px solid red !important;
  width: 300px;
  height: 300px;
  position: absolute;
  left: 200px;
  top: 120px;
}

.button-primary:disabled{
  background: #919191;
  cursor: not-allowed;
}

.swal2-container.swal2-top-end.swal2-backdrop-show{
  z-index: 9999999;
}

@media (max-width: 850px)
{
  .hidden-small {
        display: none;
    }
}

.textoAguardeBotao{
  position: absolute;
  top: 28px;
  color: white;
  font-size: 12px;
  font-style: oblique;
}